/* banner */
$primary-color: #009966;
.banner {
    background: #009966 url("../../../../media/images/index_banner.jpg") no-repeat center;
    background-size: cover;
    .row {
        align-content: center;
        padding: 8% 0;
    }
    .left_txt {
        color: #fff;
        h1 {
            margin-bottom: 1rem;
        }
        &>p {
            line-height: 1.3;
            font-size: 1.25rem;
        }
        .banner_btn {
            margin-top: 1.5rem;
            a.btn {
                padding: 0.5rem 3rem;
            }
        }
    }
    .select_block {
        border-radius: 6px;
        background-color: #006a47;
        padding: 3rem 2rem;
        ul.nav {
            border-bottom: 0;
            margin-bottom: 1.25rem;
            li {
                width: 50%;
                margin: 0;
                padding: 0;
                a {
                    color: #999;
                    background: #fff;
                    display: block;
                    text-align: center;
                    line-height: 34px;
                }
                a.tabs_left{
                   border-top-left-radius: 4px;
                   border-bottom-left-radius: 4px;
                }
                a.tabs_right{
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                 }
                a.active {
                    color: #fff;
                    background: #009966;
                }
            }
        }
        .tab-content {
            margin-bottom: 1.25rem;
            .tab-pane {
                select {
                    height: 38px;
                    color:#009966;
                    outline:none;
                    width: 100%;
                }
            }
        }
        button.btn {
            display: block;
            width: 100%;
        }
    }
}
.notice_box {
    border-bottom: 1px solid #ddd;
    padding: 1.5rem 0;
    .notice_block {
        border-right: 1px solid #ddd;
        &:last-child {
            border-right: 0;
        }
        h6 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            height: 38px;
        }
    }
    span {
        margin-right: 5px;
        font-size: .875rem;
        color: #666;
    }
}
.section {
    .product{
        margin-bottom: 1.5rem;
        .nav-tabs {
            justify-content: center;
            border-bottom: 0;
            .nav-item {
                margin-bottom: 0;
                .nav-link {
                    border: 0;
                    color: #333;
                    padding: 0 1rem;
                    h3 {
                        margin-bottom: 1rem;
                    }
                    &.active {
                        color: #096;
                    }
                }
            }
        }
    }
    .section_common {
        .re-pd{
            padding-left: .375rem;
            padding-right: .375rem;
            margin: 0.5rem 0;
        }
        .section_h3 {
            text-align: center;
            font-weight: bold;
            margin-bottom: 1rem;
            font-size: 1.35rem;
        }
        
        .product_content {
            padding: .375rem 1.25rem;
            .product_card {
                padding: 0 .5rem;
                height: 194px;
                overflow: hidden;
                position: relative;
                text-align: center;
                h5 {
                    font-weight: bold;
                    color: #606060;
                    font-size: 1.1rem;
                    margin-bottom: .75rem;
                }
                img.card_img {
                    width: 60px;
                    height: 60px;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                }
                p {
                    color: #999;
                    font-size: 0.875rem;
                    text-align: justify;
                    height: 64px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
                a.card_more {
                    display: block;
                    font-size: 0.875rem;
                    position: absolute;
                    bottom: 1rem;
                    z-index: 9;
                    left: 0;
                    right: 0;
                }
            }
            .product_card:hover {
                background: #009966;
                color: #fff;
                p {
                    color: #eee;
                }
                a {
                    color: orange;
                }
                h5 {
                    color: #fff;
                }
            }
        }
        .data_content{
            padding: 2rem 1.25rem;
            .st_data{
                text-align: center;
                p{
                    font-size: 1.25rem;
                    margin-bottom: 0;
                }
                a{
                    font-size: 1.75rem;
                }
            }
            .conf_list{
                .conf_card{
                    margin: 0;
                }
                .conf_colum{
                    padding: 1rem;
                    height: 100px;
                    overflow: hidden;
                    a{
                        display: block;
                        h6{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            height: 38px;
                            font-weight: bold;
                        }
                        p{
                            font-size: .875rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 0;
                            color: #8c8c8c;
                        }
                    }
                }
                .conf_colum:hover{
                    background: #009966;
                    a{
                        color: #fff;
                        p{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:767px){
    .banner{
        .left_txt{
            margin-bottom: 0rem;
            h1 {
                margin-bottom: 1rem;
                font-size: 1.25rem;
            }
            &>p {
                line-height: 1.5;
                font-size: 1rem;
            }
            .banner_btn{
                text-align: center;
                a.btn{
                    display: inline-block;
                    margin-right: 0!important;
                    padding: 0.5rem 2rem;
                }
            }
        }
        .select_block{
            display: none;
        }
    }
    .conf_total {
        display: none;
    }
    .notice_box .notice_block h6 {
        height: auto;
    }
    .notice_box .notice_block {
        margin-bottom: .75rem;
    }
    .notice_box .notice_block:last-child {
        margin-bottom: 0;
    }
    .section .section_common .product_content .product_card {
        height: auto;
        padding: 0;
    }
    .section .section_common .product_content .product_card p {
        height: auto;
    }
    .section .section_common .product_content .product_card a.card_more {
        position: initial;
    }
    .section .section_common .data_content .conf_list .conf_colum {
        padding: 0;
        height: auto;
        overflow: hidden;
        margin-bottom: 10px;
    }
    .section .section_common .data_content .conf_list .conf_colum a h6 {
        height: auto;
    }
}